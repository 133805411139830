html,
body,
#root {
  margin: 0;
  padding: 0;
} 

body {
  background: #EFEFEF
}

body.whitebg {
  background: white;
  padding-bottom: 50px;
}